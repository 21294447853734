<template>
  <div>
    <div :class="bg"></div>
    <Header :title="$t('navbar.studies')" />
    <StudyNav />
  </div>
</template>


<script>
import Header from '../components/Header.vue';
import StudyNav from '../components/StudyNav.vue';

export default {
  components: {
    Header,
    StudyNav,
  },
  computed: {
    bg() {
      return this.$q.platform.is.desktop ? "bg" : " ";
    },
  },

}
</script>
<style scoped>
.bg {
  position: absolute;
  top: 0;
  left: -250px;
  width: 100%;
  height: 140vh;
  background-image: url('../assets/bg-my-studies.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: -1;
  opacity: 0.2;
  transform: rotate(90deg);
}
</style>